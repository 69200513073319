import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { parseISO, isEqual } from 'date-fns';
import { getResumenPropuestasByIdSolicitud, getSolicitudById, getSolicitudesCep, getSolicitudesFiltros } from 'services/Solicitud';
import { setError } from 'redux/slices/globalSlice';

export const fetchCepsDisponibles = createAsyncThunk(
    'solicitud/fetchCepsDisponibles',
    async (_, { dispatch }) => {
        try {
            const ceps = await getSolicitudesCep();
            return ceps;
        } catch (error) {
            dispatch(setError("Ocurrió un error obteniendo las solicitudes CEP"));
            throw error;
        }
    }
);

export const fetchPropuestasBySolicitud = createAsyncThunk(
    'solicitud/fetchPropuestasBySolicitud',
    async ({ idSolicitud, soloAceptadas }, { dispatch }) => {
        try {
            const propuestas = await getResumenPropuestasByIdSolicitud(idSolicitud, soloAceptadas);
            return propuestas;
        } catch (error) {
            dispatch(setError("Ocurrió un error obteniendo las propuestas de la solicitud"));
            throw error;
        }
    }
);

export const fetchSolicitudById = createAsyncThunk('solicitud/fetchSolicitudById', async (idSolicitud, { dispatch }) => {
    try {
        const response = await getSolicitudById(idSolicitud);
        return response;
    } catch (error) {
        dispatch(setError("Ocurrió un error obteniendo la solicitud"));
        throw error;
    }
});

export const buscarPagina = createAsyncThunk('solicitud/buscarPagina', async (filtros, { dispatch }) => {
    const filtrosParse = parseFiltros(filtros);
    dispatch(setCargandoPaginas(true));
    try {
        const response = await getSolicitudesFiltros(filtrosParse);
        dispatch(setPaginas(response));
        dispatch(setCargandoPaginas(false));

        return response;
    } catch (error) {
        dispatch(setCargandoPaginas(false));
        throw error;
    }
});

const fechasIguales = (firstDate, secondDate) => {
    return isEqual(firstDate, secondDate, { includeSeconds: false, includeMilliseconds: false });
};

const parseFiltros = (filtros) => {
    let filtrosAux = { ...filtros };

    if (filtrosAux.numeroSolicitud === '') {
        filtrosAux.numeroSolicitud = null;
    }
    if (filtrosAux.anio === '') {
        filtrosAux.anio = null;
    }
    if (filtrosAux.idUsuario === '') {
        filtrosAux.idUsuario = null;
    }
    if (filtrosAux.idOrganizacion === '') {
        filtrosAux.idOrganizacion = null;
    } else {
        filtrosAux.idOrganizacion = filtrosAux.idOrganizacion.id;
    }
    if (filtrosAux.idNorma === '') {
        filtrosAux.idNorma = null;
    }
    if (filtrosAux.idIAF === '') {
        filtrosAux.idIAF = null;
    }
    if (filtrosAux.idNACE === '') {
        filtrosAux.idNACE = null;
    }
    if (filtrosAux.estado === '') {
        filtrosAux.estado = null;
    }
    if (filtros.creacionDesde === '') {
        filtrosAux.creacionDesde = null;
    }
    if (filtros.creacionHasta === '') {
        filtrosAux.creacionHasta = null;
    } else {
        const fechaCreacionDesde = parseISO(filtros.creacionDesde);
        const fechaCreacionHasta = parseISO(filtros.creacionHasta);
        if (fechasIguales(fechaCreacionDesde, new Date()) && fechasIguales(fechaCreacionHasta, new Date())) {
            filtrosAux.creacionDesde = null;
            filtrosAux.creacionHasta = null;
        }
    }

    return filtrosAux;
};

// Define el estado inicial
export const initialState = {
    numero: '',
    anio: 0,
    organizaciones: [],
    direccion: "",
    personalEfectivo: '',
    elaborado: '',
    cantidadEstudiosHaccp: null,
    turnos: [],
    idSolicitud: '',
    estado: 'BORRADOR',
    tipo: null,
    listaSolicitudes: [],
    listaCotizaciones: [],
    cep: {},
    resumenPropuestas: null,
    filtros: {
        numeroSolicitud: '',
        anio: '',
        tipo: null,
        idUsuario: '',
        idOrganizacion: '',
        idNorma: '',
        idIAF: '',
        idNACE: '',
        idSUBNACE: '',
        idCategoria: '',
        idSubCategoria: '',
        miUsuario: true,
        creacionDesde: '',
        creacionHasta: '',
        estado: '',
        ordenarPor: null,
        ordenarDescendente: true,
        numeroDePagina: 1,
        filasPorPagina: 10
    },
    paginas: {},
    cargando: false,
    cepsDisponibles: null,
    selectedCep: null,
    loadingSolicitud: false,
};

// Define un slice de Redux Toolkit
export const solicitudSlice = createSlice({
    name: 'solicitud',
    initialState,
    reducers: {
        resetState: (state) => {
            return initialState;
        },
        setNumero: (state, action) => {
            state.numero = action.payload;
        },
        setAnio: (state, action) => {
            state.anio = action.payload;
        },
        setOrganizacion: (state, action) => {
            state.organizaciones = action.payload;
        },
        setDireccion: (state, action) => {
            state.direccion = action.payload;
        },
        setListaCotizaciones: (state, action) => {
            state.listaCotizaciones = action.payload;
        },
        setPersonalEfectivo: (state, action) => {
            state.personalEfectivo = action.payload;
        },
        setElaborado: (state, action) => {
            state.elaborado = action.payload;
        },
        setCantidadEstudiosHaccp: (state, action) => {
            state.cantidadEstudiosHaccp = action.payload;
        },
        setTurnos: (state, action) => {
            state.turnos = action.payload;
        },
        setIdSolicitud: (state, action) => {
            state.idSolicitud = action.payload;
        },
        setSolicitud: (state, action) => {
            state.listaCotizaciones = action.payload.cotizaciones;
            state.numero = action.payload.numero;
            state.organizaciones = action.payload.organizaciones;
            state.elaborado = action.payload.usuario;
            state.personalEfectivo = action.payload.cantidadPersonal;
            state.cantidadEstudiosHaccp = action.payload.cantidadEstudiosHaccp;
            state.turnos = action.payload.turnos;
            state.anio = action.payload.anio;
            state.idSolicitud = action.payload.id;
            state.estado = action.payload.estado;
            state.tipo = action.payload.tipo;
        },
        setFiltros: (state, action) => {
            state.filtros = action.payload;
        },
        setPaginas: (state, action) => {
            state.paginas = action.payload;
        },
        setNumeroPagina: (state, action) => {
            state.numeroPagina = action.payload;
        },
        setCargandoPaginas: (state, action) => {
            state.cargando = action.payload;
        },
        resetFiltros: (state) => {
            state.filtros = initialState.filtros;
        },
        setEstadoSolicitud: (state, action) => {
            state.estado = action.payload;
        },
        setCeps: (state, action) => {
            state.cepsDisponibles = action.payload;
        },
        setSelectedCep: (state, action) => {
            state.selectedCep = action.payload;
        },
        setCep: (state, action) => {
            state.cep = action.payload;
        },
        setResumenPropuestas: (state, action) => {
            state.resumenPropuestas = action.payload;
        },
        resetResumenPropuestas: (state) => {
            state.resumenPropuestas = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCepsDisponibles.fulfilled, (state, action) => {
            state.cepsDisponibles = action.payload;
        });
        builder.addCase(fetchPropuestasBySolicitud.fulfilled, (state, action) => {
            state.resumenPropuestas = action.payload;
        });
        builder.addCase(buscarPagina.pending, (state) => {
            state.cargando = true;
        });
        builder.addCase(buscarPagina.fulfilled, (state, action) => {
            state.cargando = false;
        });
        builder.addCase(buscarPagina.rejected, (state) => {
            state.cargando = false;
        });
        builder.addCase(fetchSolicitudById.pending, (state) => {
            state.loadingSolicitud = true;
        });
        builder.addCase(fetchSolicitudById.fulfilled, (state, action) => {
            state.loadingSolicitud = false;
            state.listaCotizaciones = action.payload.cotizaciones;
            state.numero = action.payload.numero;
            state.organizaciones = action.payload.organizaciones;
            state.elaborado = action.payload.usuario;
            state.personalEfectivo = action.payload.cantidadPersonal;
            state.cantidadEstudiosHaccp = action.payload.cantidadEstudiosHaccp;
            state.turnos = action.payload.turnos;
            state.anio = action.payload.anio;
            state.idSolicitud = action.payload.id;
            state.estado = action.payload.estado.estado;
            state.tipo = action.payload.tipo;
        });
        builder.addCase(fetchSolicitudById.rejected, (state) => {
            state.loadingSolicitud = false;
        });
    },
});

export const {
    resetState,
    setNumero,
    setAnio,
    setOrganizacion,
    setDireccion,
    setPersonalEfectivo,
    setElaborado,
    setCantidadEstudiosHaccp,
    setTurnos,
    setIdSolicitud,
    setSolicitud,
    setFiltros,
    setPaginas,
    setNumeroPagina,
    setCargandoPaginas,
    setListaCotizaciones,
    resetFiltros,
    setEstadoSolicitud,
    setCeps,
    setSelectedCep,
    setCep,
    setResumenPropuestas,
    resetResumenPropuestas,
} = solicitudSlice.actions;

export default solicitudSlice.reducer;