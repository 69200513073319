import { configureStore } from '@reduxjs/toolkit';
import globalSlice from './slices/globalSlice';
import cursosSlice from './slices/cursosSlice';
import solicitudSlice from './slices/solicitudSlice';


export const store = configureStore({
  reducer: {
    global: globalSlice,
    cursos: cursosSlice,
    solicitud: solicitudSlice,
    editarSolicitud: solicitudSlice,
  },
});