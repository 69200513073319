import { Box, Chip, Skeleton, Switch, Typography } from "@mui/material";
import { TIPO_ESTADO } from "constants/constants";
import { fetchPropuestasBySolicitud, resetResumenPropuestas } from "redux/slices/solicitudSlice";
import TablaPropuestaResumen from "components/TablaPropuestaResumen/TablaPropuestResumen";
import ModalPortal, { ModalPortalWithoutActions } from "components/modalPortal";
import { format, parseISO } from "date-fns";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const LoaderSkeleton = () => {
    return (
        <>
            <Skeleton variant="rectangular" width={"10vw"} height={80} />
            <Skeleton sx={{ marginTop: "20px" }} variant="rectangular" width={"80vw"} height={350} />
            <Skeleton sx={{ marginTop: "20px" }} variant="rectangular" width={"10vw"} height={80} />
            <Skeleton sx={{ marginTop: "20px" }} variant="rectangular" width={"80vw"} height={350} />
            <Skeleton sx={{ marginTop: "20px" }} variant="rectangular" width={"10vw"} height={80} />
            <Skeleton sx={{ marginTop: "20px" }} variant="rectangular" width={"80vw"} height={350} />
        </>
    );

}

export const ModalResumenSolicitud = ({ onClose, isOpened }) => {

    const solicitudSlice = useSelector(state => state.solicitud);
    const dispatchSolicitud = useDispatch();
    const [soloAceptadas, setSoloAceptadas] = useState(true);
    const [loading, setLoading] = useState(true);

    useEffect(async () => {
        if (isOpened) {
            try {
                await dispatchSolicitud(fetchPropuestasBySolicitud({idSolicitud: solicitudSlice.idSolicitud, soloAceptadas}));
                // await fetchPropuestasBySolicitud(dispatchSolicitud, solicitudSlice.idSolicitud, soloAceptadas);
            } catch (error) {
            }

            setLoading(false);
        }else{
            dispatchSolicitud(resetResumenPropuestas());
            setLoading(true);
        }
    }, [isOpened, soloAceptadas]);

    

    const propuestas = solicitudSlice.resumenPropuestas;

    const parseFecha = (fechaISO) => {
        return format(parseISO(fechaISO), "dd/MM/yyyy");
    }

    const parseCodigo = (codigo, version) => {
        var partes = codigo.split("-");
        return partes[0] + "-" + partes[1].replace("1", version) + "-" + partes[2];
    }

    const getColorEstado = (estado) => {
        switch (estado) {
            case TIPO_ESTADO.ACEPTADA: {
                return 'success';
            }
            case TIPO_ESTADO.ENVIADA: {
                return 'warning';
            }
            case TIPO_ESTADO.DESCARTADA: {
                return 'error';
            }
            case TIPO_ESTADO.RECHAZADA: {
                return 'error';
            }
            default: {
                return 'secondary';
            }
        }
    }

    return (
        <ModalPortalWithoutActions
            onCancel={onClose}
            // onSave={onSave}
            title="Resumen de Propuestas"
            isOpened={isOpened}
        >
            <div style={{ height: "80vh", display: "flex", flexDirection: "column", overflowY: "scroll", paddingLeft: "20px", paddingRight: "20px" }}>

                <Box alignSelf={"flex-end"} display={"flex"} flexDirection={"row"} alignItems={"center"} >
                    <Typography>Mostrar solo aceptadas</Typography>
                    <Switch checked={soloAceptadas} onChange={(e) => setSoloAceptadas(e.target.checked)} />
                </Box>
                {
                    loading && <LoaderSkeleton />
                }
                {
                    !loading && <div style={{ width: "80vw", display: "flex", flexDirection: "column" }}>
                        {
                            propuestas !== null && propuestas.map(p => {
                                return (
                                    <>
                                        <Box key={p.id} display={"flex"} flexDirection={"row"} alignItems={"center"} sx={{ marginTop: "30px", marginBottom: "20px" }}>
                                            <Typography>{parseCodigo(p.codigoCotizacion, p.version)}</Typography>
                                            <Chip label={p.estado + " " + parseFecha(p.fechaEstado)} color={getColorEstado(p.estado)} sx={{ marginLeft: "10px" }} />
                                            {p.presupuestoCerrado && <Chip label={"Presupuesto Cerrado"} sx={{ marginLeft: "10px", backgroundColor: "#000000", color: "white"}} />}
                                        </Box>
                                        <TablaPropuestaResumen conceptos={p.conceptos} />
                                    </>
                                )
                            })

                        }
                    </div>
                }
            </div>
        </ModalPortalWithoutActions>
    );
}